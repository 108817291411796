import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../../components/layout'

import simplyVelvet from '../../images/simply-velvet.png'

import '../../styles/common-styles.css'
import '../../styles/music-styles.css'
import '../../styles/layout-styles.css'
import '../../styles/album-styles.css'

const SimplyVelvetPage = () => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  return (
    <Layout>
      <div className='pageTitle'>Simply Velvet</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      {!isMobile && (
        <div className='albumInfoWrapper'>
          <img className='albumInfoImage' src={simplyVelvet} alt='' />
          <div className='albumInfoRight'>
            <div className='albumInfoNotes text-gray-700'>
              <p className='my-4'>
                “Simply Velvet” brilliantly displays Velvet Brown’s
                musicianship, virtuosity, and overall command of the tuba.
                Accompanied by Margaret McDonald, piano and Aaron Tindall, tuba,
                this CD encompasses a huge variety of musical styles from
                Mozart’s “Queen of the Night Aria” to Peter Meechan’s “Floating
                Dreams” for Tuba and CD accompaniment.
              </p>
              <p className='my-4'>
                <a
                  href='https://potenzamusic.com/product/simply-velvet-brown/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Simply Velvet can be purchased here
                </a>
              </p>
            </div>
            <div className='soundcloudWrapper'>
              <iframe
                width='100%'
                height='400'
                scrolling='no'
                frameborder='no'
                allow='autoplay'
                src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/3199125&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=false'
              ></iframe>
              <div class='font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,  Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;' />
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='mobileAlbumInfoWrapper'>
          <img className='mobileAlbumInfoImage' src={simplyVelvet} alt='' />
          <div className='content text-gray-700'>
            <p className='my-4'>
              <iframe
                width='100%'
                height='200'
                scrolling='no'
                frameborder='no'
                allow='autoplay'
                src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/3199125&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=false'
              ></iframe>
              <div class='font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,  Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;' />
            </p>
            <p className='my-4'>
              “Simply Velvet” brilliantly displays Velvet Brown’s musicianship,
              virtuosity, and overall command of the tuba. Accompanied by
              Margaret McDonald, piano and Aaron Tindall, tuba, this CD
              encompasses a huge variety of musical styles from Mozart’s “Queen
              of the Night Aria” to Peter Meechan’s “Floating Dreams” for Tuba
              and CD accompaniment.
            </p>
            <p className='my-4'>
              <a
                href='https://potenzamusic.com/product/simply-velvet-brown/'
                target='_blank'
                rel='noreferrer'
              >
                Simply Velvet can be purchased here
              </a>
            </p>
          </div>
        </div>
      )}
      <div
        style={{ marginLeft: '15%', marginTop: '35px', marginBottom: '35px' }}
      >
        <Link to='/music'>←Back to Discography</Link>
      </div>
    </Layout>
  )
}

export default SimplyVelvetPage
